import * as z from 'zod'
import { GameName, SportName } from './lifecycle'
import { MatchScore, MatchStatus, WinHistory } from './lifecycle/match'

export interface Message {
    message: string
}

export interface PaginatedResponse<T> {
    totalItems: number
    page: T[]
}

export interface LadderItemDTO {
    position?: number
    squadName: Team
    played?: number
    win?: number
    loss?: number
    drawn?: number
    tied?: number
    noResult?: number
    points?: number
    ptsFor?: number
    ptsAgainst?: number
    percentage?: number
    netRunRate?: number
    last5?: WinHistory[]
}

export const MatchWinnerShape = z.union([
    z.literal('HOME'),
    z.literal('AWAY'),
    z.literal('DRAW'),
])
export type TeamToWin = 'HOME' | 'AWAY' | 'NONE'
export type MatchWinner = z.infer<typeof MatchWinnerShape>

export interface CompetitionTipsMember {
    avatar?: string
    name: string
    givenName: string
    familyName: string
    teamFlag?: Team
    tips: TeamToWin[]
    rank?: number
}

export interface ExpertTipsMember extends CompetitionTipsMember {
    title: string
    avatar: string
}

export type CompetitionTipsWinner = 'HOME' | 'AWAY' | 'NONE' | 'DRAW'

export const DEFAULT_PAGE_LIMIT = 10

export interface CompetitionTipsMatch {
    id: string
    home: Team
    away: Team
    winner: CompetitionTipsWinner
}

export interface CompetitionTipsDTO
    extends PaginatedResponse<CompetitionTipsMember> {
    currentUserTips: CompetitionTipsMember
    matches: CompetitionTipsMatch[]
    roundNumber: number
    isRealTimeSorted: boolean
}

export interface ExpertTipsDTO extends PaginatedResponse<ExpertTipsMember> {
    matches: CompetitionTipsMatch[]
    roundNumber: number
    isRealTimeSorted: boolean
}

export interface DisplayTeam {
    team: Team
    score: MatchScore | undefined
    ladderPosition: string | undefined
    winHistory: Array<WinHistory> | undefined
    winAtVenue: number | undefined
    lossAtVenue: number | undefined
    seasonWin: number | undefined
    seasonPlayed: number | undefined
    percentageTipped: number | undefined
    lastFiveSixes: number[] | undefined
}

export type PickPosition =
    | 'd1'
    | 'd2'
    | 'm1'
    | 'm2'
    | 'f1'
    | 'f2'
    | 'r1'
    | 'cap'
    | 'vc'

export type PickSelections = Partial<Record<PickPosition, string | null>>
export interface DisplayRoundPicks {
    roundNumber: number
    status: RoundStatus
    picks: PickSelections
}

export enum SeasonStatus {
    NotStarted = 'not-started',
    InProgress = 'in-progress',
    Completed = 'completed',
}

export enum RoundStatus {
    Scheduled = 'scheduled',
    InProgress = 'in-progress',
    Completed = 'completed',
}

export type TipStatus = 'HOME' | 'AWAY' | 'AUTO' | 'NONE' | 'N/A'

export interface HeadToHeadMatch {
    id: string
    awayTeam: DisplayTeam
    homeTeam: DisplayTeam
    localStartTime: string | undefined
    location: string | undefined
    homeScore: MatchScore | undefined
    awayScore: MatchScore | undefined
    matchStatus: MatchStatus
    winState: 'won' | 'lost' | 'default'
    isEighteenOrOver: boolean
    savedTip: TeamToWin
    isLocked: boolean
}

export interface HeadToHeadMargin {
    savedMargin?: number
    winningMargin?: number
    marginScore?: number
    isLocked: boolean
}

export interface HeadToHeadDTO {
    match: HeadToHeadMatch
    margin?: HeadToHeadMargin
}

export interface DisplayRound {
    sport: SportName
    season: string
    roundNumber: number
    matches: HeadToHeadDTO[]
    featuredMatchId: string | undefined
    status: RoundStatus
    margin: number | undefined
    resultsSummary?: ('won' | 'lost' | 'default')[]
    isEighteenOrOver: boolean
}

export interface TabtouchMatchedOdds {
    matchId: string
    homeTeam: Team
    homeOdds: number
    homeProposition: number
    homeBetLink: string
    awayTeam: Team
    awayOdds: number
    awayProposition: number
    awayBetLink: string
    matchBetLink: string
}

export interface DisplayLeaderboardEntry {
    rank: number
    previousRank?: number
    rankOutOf: number
    roundScore?: number
    totalScore: number
    roundMargin?: number
    totalMargin: number
    userId: string
    avatar?: string
    username?: string
    teamFlag?: Team
    matchesPlayed?: number
}

export interface ScoringStateDTO {
    sport: string
    season: string
    lastCalculatedRound: number
}

export interface SeasonRankDTO {
    rank: number
    rankOutOf: number
    totalScore: number
    totalMargin: number
    previousRank?: number
}

export interface DashboardDTO {
    seasons: {
        sport: SportName
        season: string
        roundResults: ScoreDTO | undefined
        seasonRank: SeasonRankDTO | undefined
    }[]
}

export interface LeaderboardDTO {
    currentUserRank?: DisplayLeaderboardEntry
    totalRows?: number
    leaderboard: DisplayLeaderboardEntry[]
}

export interface CompetitionMatchRankDTO {
    rank: number
    userId: string
    matchNumber: number
}

export interface CompetitionSeasonRankDTO {
    round: number
    rank: number
    rankOutOf: number
    userId: string
    userName: string
}

export interface MemberDTO {
    userId: string
    name: string
    username: string
    avatar?: string
    teamFlag?: Team
}

export interface MemberPublicDTO {
    username: string
    avatar?: string
    teamFlag?: Team
}

export interface CompetitionDTO {
    id: string
    name: string
    owner: MemberDTO
    isJoined: boolean
    members: MemberDTO[]
    currentUserRank?: number
    currentUserRankOutOf: number
    previousUserRank?: number
    sport: SportName
    season: string
    game: GameName
    allowNotifications: boolean | undefined
    originId: string | undefined
}

export interface CompetitionAdminDTO {
    pk: string
    competitionName: string
    isJoined: boolean
    members: MemberDTO[]
    currentUserRank?: number
    currentUserRankOutOf: number
    previousUserRank?: number
    sport: SportName
    season: string
    game: GameName
    ownerSub: string
}

export interface CompetitionPartialDTO {
    id: string
    name: string
    owner: MemberPublicDTO
    members: MemberPublicDTO[]
    sport: SportName
    season: string
    game: GameName
}

export interface ProfileDTO {
    username: string
    givenName: string
    familyName: string
    avatar?: string
    teamFlag?: Team
    address?: {
        id?: string
        unitNumber?: string
        streetNumber: string
        streetName: string
        suburb: string
        postcode: string
        state: string // available twice
    }
    dateOfBirth: string
    phoneNumber?: string
    state: string
    isDeleted: boolean
}

export interface GameDTO {
    sport: SportName
    season: string
    game: GameName
    status: SeasonStatus
    totalRounds: number
    currentRound: number
    highestOpenRound: number
    config?: {
        prizing: {
            title: string
            content: string
            subtext?: string
            image?: string
        }[]
        termsAndConditions: string
    }
}

export interface MajorPrizeWinner {
    prize: string
    winner: string
}

export interface RoundWinner {
    round: number
    type: 'finalist' | 'prize'
    name: string
}

export interface WinnersDTO {
    hideWinners?: boolean
    majorPrizeWinners: MajorPrizeWinner[]
    maxRounds: number
    roundWinners: RoundWinner[]
}

export interface RegisteredGameDTO {
    sport: SportName
    season: string
    game: GameName
    team: Team
}

export interface CompetitionSummaryDTO {
    id: string
    format: string
    sport: string
    name: string
    originId?: string
}

export interface ScoreDTO {
    round: number
    score: number
    totalScore: number
    margin: number
    totalMargin: number
    matchesPlayed: number
}

export interface StatsDTO {
    totalScore: number
    totalMargin: number
    numberOfPerfectRounds: number
    numberOfPerfectMargins: number
}

export interface PickScoreDTO {
    round: number
    points: number
    totalPoints: number
}

export interface RoundPlayerSummaryDTO {
    playerId: string
    name: string
    team: Team
    positions: RoundPlayerPosition[]
}

export interface RoundPlayerDTO {
    playerId: string
    givenName: string
    familyName: string
    positions: RoundPlayerPosition[]
    team: Team
    status: RoundPlayerStatus

    uncontestedMarks: number
    timeOnGroundPercentage: number
    tackles: number
    spoils: number
    marks: number
    kicks: number
    hitoutsToAdvantage: number
    hitouts: number
    handballs: number
    goals: number
    freesFor: number
    freesAgainst: number
    disposals: number
    contestedMarks: number
    clangers: number
    clangerDisposals: number
    behinds: number
    jumperNumber: number

    seasonAccuracyPercentage: number
    seasonBehindAssists: number
    seasonBehinds: number
    seasonGoalAssists: number
    seasonGoals: number
    seasonTotalAssists: number
    careerHighDisposals: number
    careerHighGoals: number
    careerHighMarks: number
    seasonAverageDisposals: number
    seasonAverageHandballs: number
    seasonAverageKicks: number
    seasonAverageMarks: number
    seasonMatches: number
}

export enum RoundPlayerPosition {
    FORWARD = 'FWD',
    MIDFIELD = 'MID',
    RUCK = 'RUCK',
    DEFENDER = 'DEF',
}

export enum RoundPlayerStatus {
    WONT_PLAY = 'WONT_PLAY',
    MIGHT_PLAY = 'MIGHT_PLAY',
    WILL_PLAY = 'WILL_PLAY',
    IS_PLAYING = 'IS_PLAYING',
    DID_PLAY = 'DID_PLAY',
    DIDNT_PLAY = 'DIDNT_PLAY',
}

export const VenueShape = z.union([
    z.literal('AO'),
    z.literal('ACCOR'),
    z.literal('CITI'),
    z.literal('COFFS'),
    z.literal('GABBA'),
    z.literal('GMHBA'),
    z.literal('MARVEL'),
    z.literal('MCG'),
    z.literal('METRICON'),
    z.literal('OPTUS'),
    z.literal('GIANTS'),
    z.literal('SCG'),
    z.literal('SYDSHOWGROUNDS'),
    z.literal('TIO'),
    z.literal('TRAEGER'),
    z.literal('CAZALYS'),
    z.literal('MANUKA'),
    z.literal('MARS'),
    z.literal('TASUNIVERSITY'),
    z.literal('BLUNDSTONE'),
    z.literal('STDAUS'),
    z.literal('WACA'),
    z.literal('LAVINGTON'),
    z.literal('NSO'),
    z.literal('NORWOOD'),
    z.literal('ADELAIDEHILLS'),
    z.literal('HERITAGEBANK'),

    z.literal('PENTANET'),
    z.literal('LEEDEVILLE'),
    z.literal('LANEGROUP'),
    z.literal('FREMANTLECBO'),
    z.literal('MINERALRES'),
    z.literal('REVO'),
    z.literal('STEELBLUE'),
    z.literal('BROOME'),
    z.literal('GERALDTON'),
    z.literal('TBC'),
])

export type Venue = z.TypeOf<typeof VenueShape>

export const Venues: { [key in Venue]: key } = {
    AO: 'AO',
    ACCOR: 'ACCOR',
    CITI: 'CITI',
    COFFS: 'COFFS',
    GABBA: 'GABBA',
    GIANTS: 'GIANTS',
    GMHBA: 'GMHBA',
    MARVEL: 'MARVEL',
    MCG: 'MCG',
    METRICON: 'METRICON',
    OPTUS: 'OPTUS',
    SCG: 'SCG',
    SYDSHOWGROUNDS: 'SYDSHOWGROUNDS',
    TIO: 'TIO',
    TRAEGER: 'TRAEGER',
    CAZALYS: 'CAZALYS',
    MANUKA: 'MANUKA',
    MARS: 'MARS',
    TASUNIVERSITY: 'TASUNIVERSITY',
    BLUNDSTONE: 'BLUNDSTONE',
    STDAUS: 'STDAUS',
    WACA: 'WACA',
    LAVINGTON: 'LAVINGTON',
    NSO: 'NSO',
    NORWOOD: 'NORWOOD',
    ADELAIDEHILLS: 'ADELAIDEHILLS',
    HERITAGEBANK: 'HERITAGEBANK',

    PENTANET: 'PENTANET',
    LEEDEVILLE: 'LEEDEVILLE',
    LANEGROUP: 'LANEGROUP',
    FREMANTLECBO: 'FREMANTLECBO',
    MINERALRES: 'MINERALRES',
    REVO: 'REVO',
    STEELBLUE: 'STEELBLUE',
    BROOME: 'BROOME',
    GERALDTON: 'GERALDTON',
    TBC: 'TBC',
}

export const venueInfo: Readonly<
    { [key in Venue]: Readonly<{ name: string; location: string; key: key }> }
> = {
    AO: {
        key: 'AO',
        name: 'Adelaide Oval',
        location: 'Adelaide, South Australia',
    },
    ACCOR: {
        key: 'ACCOR',
        name: 'Accor Stadium',
        location: 'Sydney, New South Wales',
    },
    CITI: {
        key: 'CITI',
        name: 'CitiPower Centre',
        location: 'St Kilda, Victoria',
    },
    COFFS: {
        key: 'COFFS',
        name: 'Coffs International Stadium',
        location: 'Coffs Harbour, New South Wales',
    },
    GABBA: {
        key: 'GABBA',
        name: 'Gabba',
        location: 'Brisbane, Queensland',
    },
    GMHBA: {
        key: 'GMHBA',
        name: 'GMHBA Stadium',
        location: 'Geelong, Victoria',
    },
    MARVEL: {
        key: 'MARVEL',
        name: 'Marvel Stadium',
        location: 'Melbourne, Victoria',
    },
    MCG: {
        key: 'MCG',
        name: 'Melbourne Cricket Ground',
        location: 'Melbourne, Victoria',
    },
    METRICON: {
        key: 'METRICON',
        name: 'Metricon Stadium',
        location: 'Gold Coast, Queensland',
    },
    OPTUS: {
        key: 'OPTUS',
        name: 'Optus Stadium',
        location: 'Perth, Western Australia',
    },
    GIANTS: {
        key: 'GIANTS',
        name: 'GIANTS Stadium',
        location: 'Sydney, New South Wales',
    },
    SCG: {
        key: 'SCG',
        name: 'Sydney Cricket Ground',
        location: 'Sydney, New South Wales',
    },
    SYDSHOWGROUNDS: {
        key: 'SYDSHOWGROUNDS',
        name: 'Sydney Showgrounds Stadium',
        location: 'Sydney, New South Wales',
    },
    TIO: {
        key: 'TIO',
        name: 'TIO Stadium',
        location: 'Darwin, Northern Territory',
    },
    TRAEGER: {
        key: 'TRAEGER',
        name: 'TIO Traeger Park',
        location: 'Alice Springs, Northern Territory',
    },
    CAZALYS: {
        key: 'CAZALYS',
        name: "Cazaly's Stadium",
        location: 'Cairns, Queensland',
    },
    MANUKA: {
        key: 'MANUKA',
        name: 'Manuka Oval',
        location: 'Canberra, Australian Capital Territory',
    },
    MARS: {
        key: 'MARS',
        name: 'Mars Stadium',
        location: 'Ballarat, Victoria',
    },
    TASUNIVERSITY: {
        key: 'TASUNIVERSITY',
        name: 'University of Tasmania Stadium',
        location: 'Launceston, Tasmania',
    },
    BLUNDSTONE: {
        key: 'BLUNDSTONE',
        name: 'Blundstone Arena',
        location: 'Hobart, Tasmania',
    },
    STDAUS: {
        key: 'STDAUS',
        name: 'Stadium Australia',
        location: 'Sydney, Australia',
    },
    WACA: { key: 'WACA', name: 'W.A.C.A.', location: 'Perth, Australia' },
    LAVINGTON: {
        key: 'LAVINGTON',
        name: 'Lavington Sports Ground',
        location: 'Albury, Australia',
    },
    NSO: {
        key: 'NSO',
        name: 'North Sydney Oval',
        location: 'Sydney, Australia',
    },
    NORWOOD: {
        key: 'NORWOOD',
        name: 'Norwood Oval',
        location: 'Adelaide, Australia',
    },
    ADELAIDEHILLS: {
        key: 'ADELAIDEHILLS',
        name: 'Adelaide Hills Oval',
        location: 'Adelaide, Australia',
    },

    PENTANET: {
        key: 'PENTANET',
        name: 'Pentanet Stadium',
        location: '',
    },
    LEEDEVILLE: {
        key: 'LEEDEVILLE',
        name: 'Leederville Oval',
        location: '',
    },
    LANEGROUP: {
        key: 'LANEGROUP',
        name: 'Lane Group Stadium',
        location: '',
    },
    FREMANTLECBO: {
        key: 'FREMANTLECBO',
        name: 'Fremantle Community Bank Oval',
        location: '',
    },
    MINERALRES: {
        key: 'MINERALRES',
        name: 'Mineral Resources Park',
        location: '',
    },
    REVO: {
        key: 'REVO',
        name: 'Revo Fitness Stadium',
        location: '',
    },
    STEELBLUE: {
        key: 'STEELBLUE',
        name: 'Steel Blue Oval',
        location: '',
    },
    BROOME: {
        key: 'BROOME',
        name: 'Broome',
        location: '',
    },
    GERALDTON: {
        key: 'GERALDTON',
        name: 'Geraldton (WA)',
        location: '',
    },
    HERITAGEBANK: {
        key: 'HERITAGEBANK',
        name: 'Heritage Bank Stadium',
        location: 'Gold Coast, Queensland',
    },
    TBC: { key: 'TBC', name: 'To be confirmed', location: '' },
}

export enum Team {
    ADELAIDE = 'ADEL',
    BRISBANE = 'BRIS',
    CARLTON = 'CARL',
    COLLINGWOOD = 'COLL',
    ESSENDON = 'ESS',
    FREMANTLE = 'FRE',
    GEELONG = 'GEEL',
    GOLDCOAST = 'GC',
    GIANTS = 'GWS',
    HAWTHORN = 'HAW',
    MELBOURNE = 'MELB',
    NORTH = 'NM',
    PORT = 'PORT',
    RICHMOND = 'RICH',
    STKILDA = 'STK',
    SYDNEY = 'SYD',
    WESTCOAST = 'WCE',
    WESTERNBULLDOGS = 'WB',

    HEAT = 'HEA',
    HURRICANES = 'HUR',
    RENEGADES = 'MELR',
    SCORCHERS = 'SCO',
    SIXERS = 'SYDS',
    STARS = 'MELS',
    STRIKERS = 'STR',
    THUNDER = 'SYDT',

    //TODO: Verify these codes
    WPFALCONS = 'W.PER',
    EFSHARKS = 'E.FRE',
    PEELTHUNDER = 'PEEL',
    CLAREMONTTIGERS = 'CLA',
    SWANDISTRICT = 'SWA',
    PERTHDEMONS = 'PER',
    SFBULLDOGS = 'S.FRE',
    EPROYALS = 'E.PER',
    SUBIACOLIONS = 'SUBI',

    TBC = 'TBC',
}

/** Tuples of the team code and natural team name */
export const aflTeams: [Team, string][] = [
    [Team.ADELAIDE, 'Adelaide'],
    [Team.BRISBANE, 'Brisbane'],
    [Team.CARLTON, 'Carlton'],
    [Team.COLLINGWOOD, 'Collingwood'],
    [Team.ESSENDON, 'Essendon'],
    [Team.FREMANTLE, 'Fremantle'],
    [Team.GEELONG, 'Geelong'],
    [Team.GOLDCOAST, 'Gold Coast'],
    [Team.GIANTS, 'GWS'],
    [Team.HAWTHORN, 'Hawthorn'],
    [Team.MELBOURNE, 'Melbourne'],
    [Team.NORTH, 'North Melbourne'],
    [Team.PORT, 'Port Adelaide'],
    [Team.RICHMOND, 'Richmond'],
    [Team.STKILDA, 'St Kilda'],
    [Team.SYDNEY, 'Sydney'],
    [Team.WESTCOAST, 'West Coast'],
    [Team.WESTERNBULLDOGS, 'Western Bulldogs'],
]

/** Tuples of the team code and natural team name */
export const cricketTeams: [Team, string][] = [
    [Team.HEAT, 'Brisbane Heat'],
    [Team.HURRICANES, 'Hobart Hurricanes'],
    [Team.RENEGADES, 'Melbourne Renegades'],
    [Team.STARS, 'Melbourne Stars'],
    [Team.SCORCHERS, 'Perth Scorchers'],
    [Team.STRIKERS, 'Adelaide Strikers'],
    [Team.SIXERS, 'Sydney Sixers'],
    [Team.THUNDER, 'Sydney Thunder'],
]

/** Tuples of the team code and natural team name */
export const waflTeams: [Team, string][] = [
    [Team.WPFALCONS, 'West Perth Falcons'],
    [Team.EFSHARKS, 'East Freo Sharks'],
    [Team.PEELTHUNDER, 'Peel Thunder'],
    [Team.CLAREMONTTIGERS, 'Claremont Tigers'],
    [Team.SWANDISTRICT, 'Swan Districts'],
    [Team.PERTHDEMONS, 'Perth Demons'],
    [Team.SFBULLDOGS, 'South Freo Bulldogs'],
    [Team.EPROYALS, 'East Perth Royals'],
    [Team.SUBIACOLIONS, 'Subiaco Lions'],
]

/** Tuples of the team code and natural team name */
export const sportsTeams: Record<SportName, [Team, string][]> = {
    Cricket: cricketTeams,
    AFL: aflTeams,
    WAFL: waflTeams,
}

/** A map of team codes to natural language afl team names */
export const aflTeamNames = Object.fromEntries(aflTeams)
export const waflTeamNames = Object.fromEntries(waflTeams)

/** A list of the afl team codes */
export const aflTeamCodes = aflTeams.map(([code]) => code)

/** A list of the wafl team codes */
export const waflTeamCodes = waflTeams.map(([code]) => code)

/** A map of team codes to natural language cricket team names */
export const cricketTeamNames = Object.fromEntries(cricketTeams)

/** A list of the cricket team codes */
export const cricketTeamCodes = cricketTeams.map(([code]) => code)

/** A lookup from team codes to natural language team names */
export const abbreviationToNameMap: { [abbreviation: string]: string } = {
    ...aflTeamNames,
    ...cricketTeamNames,
    ...waflTeamNames,
    [Team.TBC]: 'To Be Confirmed',
}

export interface AddressSummary {
    description: string
    placeId?: string | undefined
}

export interface FormattedAddress {
    id?: string
    unitNumber?: string
    streetNumber: string
    streetName: string
    postcode: string
    suburb: string
    state: string
}

export interface CreateResponse {
    id: string
}

export interface PartialSuccessResponse {
    failed: string[] | undefined
}

// Duplicated from domain/match in api-handlers
export interface Winner {
    matchId: string
    winner: MatchWinner
    winningMargin: number
}

export interface UserBatch {
    id: number
    sub: string
}

export interface EndOfRoundResultsDetails {
    sub: string
    score: number
    roundRank: number
    roundMargin: number
}
export interface ResultsBatch {
    id: number
    sub: string
    score: number
    roundRank: number
    roundMargin: number
}
export interface SortingBatch {
    batchFileNumber: number
    scoringFile: string
    sortedBatch: UserBatch[] | ResultsBatch[]
}
export interface ScoringBatch {
    sport: SportName
    season: string
    game: 'Tipping' | 'Picking'
    timestamp: string
    round: number
    winners: Winner[]
    featuredMatchId: string | undefined
    batch: {
        id: number
        filePath: string
    }
}

export interface EndOfRoundResultsBatch {
    sport: SportName
    season: string
    round: number
    winners: Winner[]
    batch: {
        id: number
        filePath: string
    }
}

export interface IWriteBatchToS3Helper {
    (
        scoringFile: string,
        usersBatch: UserBatch[] | EndOfRoundResultsBatch[],
        batchFileNumber: number,
    ): void
}

export interface ReportFields {
    WANUserId: string

    email: string
    name: string
    underEighteen: boolean
    outsideWA: boolean
    joined: string

    score: number
    matchesPlayed: number

    roundRank: number
    roundMargin: number

    seasonRank: number
    seasonMargin: number
    seasonScore: number

    sport: SportName
    season: string
    round: number
}
export interface SimpleTip {
    teamToWin: TeamToWin
    matchId: string
}

export type TipsWithTeamFlag = SimpleTip & {
    homeTeam: Team
    awayTeam: Team
    featuredMatch?: boolean
    margin?: number
}

// for sendgrid edm
export const aflTeamsFlagUrl: [Team, string][] = [
    [
        Team.ADELAIDE,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/fad87e78-51c9-4827-92f9-ef721824c5ba/22x32.png',
    ],
    [
        Team.BRISBANE,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/a9680f91-fea2-4ee0-bed9-8a25aaf03c18/22x32.png',
    ],
    [
        Team.CARLTON,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/25d44507-7f40-46f7-ad38-090e278f171e/22x32.png',
    ],
    [
        Team.COLLINGWOOD,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/8804351d-e25a-4c72-b0d7-18b8e3b9b1cf/22x32.png',
    ],
    [
        Team.ESSENDON,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/75e84d6f-6721-405c-9b45-eaee6a4c58d2/22x32.png',
    ],
    [
        Team.FREMANTLE,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/df4307c4-6f6d-439f-a81e-5f2426fdc8a4/22x32.png',
    ],
    [
        Team.GEELONG,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/298fcdb2-4de6-4e73-a237-71aaf0fe20df/22x32.png',
    ],
    [
        Team.GIANTS,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/33da3351-d199-4b26-a560-4c194a279253/22x32.png',
    ],
    [
        Team.GOLDCOAST,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/0bf9f1ba-8de6-4ecd-a43b-0f2447feafa2/22x32.png',
    ],
    [
        Team.HAWTHORN,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/b172a529-a897-4d65-8043-f53ef6f481a7/22x32.png',
    ],

    [
        Team.MELBOURNE,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/c5249b4b-f4c2-48c5-8e7c-0bf9738313d5/22x32.png',
    ],
    [
        Team.NORTH,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/5dee25f7-b5fd-45bd-83a1-3b956e1fa74f/22x32.png',
    ],
    [
        Team.PORT,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/231317cc-f4d0-4246-bad0-fc611a734e7d/22x32.png',
    ],

    [
        Team.RICHMOND,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/76759daa-fb7c-4f26-9972-8fba8f73e282/22x32.png',
    ],

    [
        Team.STKILDA,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/f784426f-0396-47b8-955e-5966e401694e/22x32.png',
    ],

    [
        Team.SYDNEY,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/bd5f5058-709e-4bbf-9a2c-9a22b690ac6e/22x32.png',
    ],

    [
        Team.WESTCOAST,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/1f898f5c-6427-4b41-849d-ffbd834b94e2/22x32.png',
    ],
    [
        Team.WESTERNBULLDOGS,
        'http://cdn.mcauto-images-production.sendgrid.net/10e297da236a66bb/3a118791-1b69-4fbf-823b-f364d84e31f9/22x32.png',
    ],

    [Team.TBC, ''],
    [Team.THUNDER, ''],
    [Team.SCORCHERS, ''],
    [Team.SIXERS, ''],
    [Team.STARS, ''],
    [Team.HEAT, ''],
    [Team.HURRICANES, ''],
    [Team.STRIKERS, ''],
    [Team.RENEGADES, ''],
]

export const aflTeamsFlagUrlEnteries = Object.fromEntries(aflTeamsFlagUrl)

export const aflTeamsShortened: [Team, string][] = [
    [Team.ADELAIDE, 'Adelaide'],
    [Team.BRISBANE, 'Brisbane'],
    [Team.CARLTON, 'Carlton'],
    [Team.COLLINGWOOD, 'Collingwood'],
    [Team.ESSENDON, 'Essendon'],
    [Team.FREMANTLE, 'Fremantle'],
    [Team.GEELONG, 'Geelong'],
    [Team.GOLDCOAST, 'Gold Coast'],
    [Team.GIANTS, 'GWS'],
    [Team.HAWTHORN, 'Hawthorn'],
    [Team.MELBOURNE, 'Melbourne'],
    [Team.NORTH, 'North Melb.'],
    [Team.PORT, 'Port Adelaide'],
    [Team.RICHMOND, 'Richmond'],
    [Team.STKILDA, 'St Kilda'],
    [Team.SYDNEY, 'Sydney'],
    [Team.WESTCOAST, 'West Coast'],
    [Team.WESTERNBULLDOGS, 'Bulldogs'],
]

export const aflTeamNamesShortened = Object.fromEntries(aflTeamsShortened)

export interface DashboardNotification {
    id: string
    title: string
    content: string
    sport?: SportName
    showFrom: string
    showTo: string
    versions: string[]
    platforms: string[]
}
