import { Box, Checkbox, Grid, GridItem, Stack, Text } from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { useState } from 'react'
import { AuditLogDTO, SportName } from '../../data'
import { FormattedEvent } from './FormattedEvent'
import { BlockHeader } from './Shared'
import { isSubset, isSome } from '../../helpers/array-manipulations'
import { capitalize } from '../../helpers/string-manipulations'

interface MappedAudit {
    date: Date
    type: string
    event: string | { [key: string]: any }
}

const SPORTS_ARRAY: SportName[] = ['AFL', 'Cricket', 'WAFL']

export function AuditLogs({ auditLogs }: { auditLogs: AuditLogDTO[] }) {
    const hasLogs = auditLogs.length > 0
    const mappedLogs = auditLogs.map<MappedAudit>((audit) => {
        const date = new Date(audit.sk.split('#')[1])
        let type = 'unknown'
        let event = audit.event
        try {
            const json = JSON.parse(audit.event)
            json.rootKey && delete json.rootKey
            if (json.type) {
                type = json.type
            }
            event = json
        } catch (e) {
            console.warn('failed to parse audit log', audit, e)
        }
        const mappedAudit: MappedAudit = {
            date,
            type,
            event,
        }
        return mappedAudit
    })

    const distinctTypes = Array.from(new Set(mappedLogs.map((x) => x.type)))

    const userTypes = distinctTypes.filter((type) =>
        type.toLowerCase().includes('user'),
    )
    const systemTypes = distinctTypes.filter(
        (type) => !type.toLowerCase().includes('user'),
    )

    const [typeFilters, setTypeFilters] = useState<string[]>(systemTypes)
    const [sportFilters, setSportFilters] = useState<SportName[]>(SPORTS_ARRAY)
    const [isSportFilterChecked, setIsSportFilterChecked] = useState(false)

    let logsToDisplay = mappedLogs.filter((l) => !typeFilters.includes(l.type))
    if (isSportFilterChecked) {
        logsToDisplay = logsToDisplay.filter(
            (l) => l.event['sport'] && sportFilters.includes(l.event['sport']),
        )
    }

    const allUserChecked = !isSubset(typeFilters, userTypes)
    const isUserIndeterminate = isSome(typeFilters, userTypes) && allUserChecked

    const allSystemChecked = !isSubset(typeFilters, systemTypes)
    const isSystemIndeterminate =
        isSome(typeFilters, systemTypes) && allSystemChecked

    const handleCheckbox = (section: 'user' | 'system', type?: string) => {
        setTypeFilters((prev) => {
            if (section === 'user' && !type) {
                if (allUserChecked) {
                    return [...prev, ...userTypes]
                } else {
                    return prev.filter(
                        (type) => !type.toLowerCase().includes('user'),
                    )
                }
            } else if (section === 'system' && !type) {
                if (allSystemChecked) {
                    return [...prev, ...systemTypes]
                } else {
                    return prev.filter((type) =>
                        type.toLowerCase().includes('user'),
                    )
                }
            } else if (type) {
                if (prev.includes(type)) {
                    return prev.filter((k) => k !== type)
                }
                return [...prev, type]
            }
        })
    }

    const handleSportCheckbox = (sportName: SportName) => {
        if (!sportFilters.includes(sportName)) {
            setSportFilters([...sportFilters, sportName])
        } else {
            setSportFilters(sportFilters.filter((sport) => sport !== sportName))
        }
    }

    return (
        <Box>
            <BlockHeader heading="Logs" />

            {!hasLogs && (
                <Text fontStyle="italic">The user has no audit events</Text>
            )}

            {hasLogs && (
                <details>
                    <summary>
                        <strong style={{ cursor: 'pointer' }}>View</strong>
                    </summary>
                    <Box
                        mt="2"
                        mb="4"
                        pb="4"
                        borderBottom="1px solid lightgray"
                    >
                        <Grid templateColumns="repeat(2, 1fr)" gap="1">
                            <GridItem>
                                <Checkbox
                                    defaultChecked={true}
                                    isChecked={allUserChecked}
                                    isIndeterminate={isUserIndeterminate}
                                    onChange={() => handleCheckbox('user')}
                                >
                                    <Text>User actions</Text>
                                </Checkbox>
                                <Stack ml="4">
                                    {distinctTypes.map((type) => {
                                        return (
                                            type
                                                .toLowerCase()
                                                .includes('user') && (
                                                <Checkbox
                                                    key={type}
                                                    name={type}
                                                    defaultChecked={true}
                                                    isChecked={
                                                        !typeFilters.includes(
                                                            type,
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleCheckbox(
                                                            'user',
                                                            type,
                                                        )
                                                    }
                                                >
                                                    <Text>
                                                        {capitalize(
                                                            type.replace(
                                                                /-/g,
                                                                ' ',
                                                            ),
                                                        )}
                                                    </Text>
                                                </Checkbox>
                                            )
                                        )
                                    })}
                                </Stack>
                            </GridItem>
                            <GridItem>
                                <Checkbox
                                    defaultChecked={true}
                                    isChecked={allSystemChecked}
                                    isIndeterminate={isSystemIndeterminate}
                                    onChange={() => handleCheckbox('system')}
                                >
                                    <Text>System actions</Text>
                                </Checkbox>
                                <Stack ml="4">
                                    {distinctTypes.map((type) => {
                                        return (
                                            !type
                                                .toLowerCase()
                                                .includes('user') && (
                                                <Checkbox
                                                    key={type}
                                                    name={type}
                                                    defaultChecked={false}
                                                    isChecked={
                                                        !typeFilters.includes(
                                                            type,
                                                        )
                                                    }
                                                    onChange={() =>
                                                        handleCheckbox(
                                                            'system',
                                                            type,
                                                        )
                                                    }
                                                >
                                                    <Text>
                                                        {capitalize(
                                                            type.replace(
                                                                /-/g,
                                                                ' ',
                                                            ),
                                                        )}
                                                    </Text>
                                                </Checkbox>
                                            )
                                        )
                                    })}
                                </Stack>
                            </GridItem>
                            <GridItem paddingTop="1rem">
                                <Checkbox
                                    isChecked={isSportFilterChecked}
                                    isIndeterminate={isSystemIndeterminate}
                                    onChange={() => {
                                        setIsSportFilterChecked(
                                            !isSportFilterChecked,
                                        )
                                    }}
                                >
                                    <Text>Filter by sport</Text>
                                </Checkbox>
                                <Stack ml="4">
                                    {SPORTS_ARRAY.map((sport) => {
                                        return (
                                            <Checkbox
                                                isDisabled={
                                                    !isSportFilterChecked
                                                }
                                                isChecked={sportFilters.includes(
                                                    sport,
                                                )}
                                                onChange={() =>
                                                    handleSportCheckbox(sport)
                                                }
                                            >
                                                <Text>{sport}</Text>
                                            </Checkbox>
                                        )
                                    })}
                                </Stack>
                            </GridItem>
                        </Grid>
                    </Box>
                    <Box>
                        {logsToDisplay.map((a, index) => (
                            <Box
                                key={index + 'audit-logs'}
                                _notLast={{ mb: '5' }}
                            >
                                <Text color="#3333d5" fontWeight="600">
                                    {format(a.date, 'd MMM yyyy, hh:mm:ss b')}
                                </Text>
                                <Text color="#5e5eff" fontWeight="600" as="i">
                                    {a.type}
                                </Text>
                                <Box>
                                    <FormattedEvent event={a.event} />
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </details>
            )}
        </Box>
    )
}
