import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogOverlay,
    Grid,
    GridItem,
    Text,
    Link,
    useDisclosure,
    Tooltip,
    Button,
} from '@chakra-ui/react'
import React from 'react'
import { CompetitionDTO, usePostAddToCompetition } from '../../../data'

export function CompetitionItem({
    comp,
    subject,
    isAddBtn,
    isRefetching,
    refetchCompetitions,
}: {
    comp: CompetitionDTO
    subject?: string
    isAddBtn?: boolean
    isRefetching?: boolean
    refetchCompetitions?: Function
}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [errorMessage, setErrorMessage] = React.useState('')
    const cancelRef = React.useRef()
    const [
        addToCompetition,
        { data, isLoading, isError, error, reset },
    ] = usePostAddToCompetition()

    if (isError) {
        onOpen()
        setErrorMessage(error as string)
        reset()
    } else if (!isError && data) {
        reset()
        refetchCompetitions()
    }

    const url = `https://thegame.com.au/${comp.sport}/${comp.game}/comp/${comp.pk}`

    return (
        <Grid
            display="grid"
            templateColumns="repeat(2, 1fr)"
            templateRows="repeat(2, 1fr)"
            mb="2"
            gap="1"
            borderBottom="1px solid lightgray"
            pb="4"
        >
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent padding="5">
                    <AlertDialogCloseButton />
                    <AlertDialogBody color="red">
                        {errorMessage}
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialog>
            <GridItem colSpan={2}>
                <Text
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                >
                    <Tooltip
                        label={
                            <div>
                                <p>Sport: {comp.sport}</p>
                                <p>Season: {comp.season}</p>
                            </div>
                        }
                        aria-label="Competition details"
                    >
                        {comp.competitionName}
                    </Tooltip>
                </Text>
            </GridItem>

            <GridItem>
                <Link
                    target="_blank"
                    color="#001AFF"
                    marginRight="5"
                    href={url}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                >
                    {comp.pk}
                </Link>
                <Text as="i">
                    {comp.members.length === 1
                        ? comp.members.length + ' member'
                        : comp.members.length + ' members'}
                </Text>
            </GridItem>
            <GridItem textAlign="right">
                {comp.isJoined ? (
                    <Text as="i">
                        {comp.currentUserRank
                            ? `#${comp.currentUserRank} of ${comp.currentUserRankOutOf}`
                            : 'TBC'}
                    </Text>
                ) : (
                    isAddBtn && (
                        <Button
                            isLoading={isLoading || isRefetching}
                            variant="link"
                            fontWeight={500}
                            color="#001AFF"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={() => {
                                addToCompetition({
                                    competitionId: comp.pk,
                                    subject,
                                })
                            }}
                            role="button"
                        >
                            Add &#x2B;
                        </Button>
                    )
                )}
            </GridItem>
        </Grid>
    )
}
