import React, { useState } from 'react'
import {
    Text,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@chakra-ui/react'
import { EditIcon, LockIcon, UnlockIcon, StarIcon } from '@chakra-ui/icons'
import { MdSwapHoriz } from 'react-icons/md'
import {
    MatchInfoDTO,
    Round,
    Season,
    useGetMatchInfo,
    mapTeamName,
    MatchDTO,
} from '../data'
import { useGetRounds } from '../hooks/useGetRounds'
import { useEditMatch, Action } from '../hooks/useEditMatch'
import { StyledModal } from '../components/StyledModal'

interface FixturesProps {
    season: Season
}

export function Fixtures({ season }: FixturesProps) {
    const { data: matchInfo, refetch: refetchMatches } = useGetMatchInfo(
        season.sport,
        season.season,
    )
    const { data: roundData } = useGetRounds({
        sport: season.sport,
        season: season.season,
    })
    const [selectedRound, setSelectedRound] = useState(season.currentRound)
    const [matchToEdit, setMatchToEdit] = useState<MatchDTO>()

    const fixturesTable = (
        matchInfo: MatchInfoDTO,
        roundData: {
            rounds: Round[]
        },
    ) => {
        const matches = Object.values(matchInfo.matches).sort((a, b) =>
            (a.startTime || '')?.localeCompare(b.startTime || ''),
        )

        return (
            <>
                <Select
                    value={selectedRound}
                    onChange={(e) => setSelectedRound(+e.target.value)}
                    width={'320px'}
                    height={'40px'}
                    mt="2"
                >
                    {roundData.rounds.map((round) => {
                        return (
                            <option
                                key={round.roundNumber}
                                value={round.roundNumber}
                            >{`Round ${round.roundNumber}`}</option>
                        )
                    })}
                </Select>
                <TableContainer
                    borderColor="blackAlpha.250"
                    borderWidth="thin"
                    padding="4"
                    borderRadius="12"
                    mt="4"
                    mb="4"
                >
                    <Table variant="striped" colorScheme="gray">
                        <Thead>
                            <Tr>
                                <Th>Round</Th>
                                <Th>Match ID</Th>
                                <Th>Match Start (local time)</Th>
                                <Th>Home Team</Th>
                                <Th>Away Team</Th>
                                <Th>Status</Th>
                                <Th>Winner</Th>
                                <Th>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {matches.map((match) => {
                                const getWinner = (matchData: MatchDTO) => {
                                    if (matchData.winner === 'HOME') {
                                        return mapTeamName(matchData.homeTeam)
                                    } else if (matchData.winner === 'AWAY') {
                                        return mapTeamName(matchData.awayTeam)
                                    } else {
                                        return '--'
                                    }
                                }

                                if (match.round === selectedRound) {
                                    return (
                                        <Tr key={match.matchId}>
                                            <Td>{match.round}</Td>
                                            <Td>
                                                {match.matchId}{' '}
                                                {match.isFeatured
                                                    ? '(FEATURED MATCH) '
                                                    : ' '}
                                                {match.isLocked && <LockIcon />}
                                            </Td>
                                            <Td>
                                                {match.startTime
                                                    ? new Date(
                                                          match.startTime,
                                                      ).toLocaleString()
                                                    : 'N/A'}
                                            </Td>
                                            <Td>
                                                {mapTeamName(match.homeTeam)}
                                            </Td>
                                            <Td>
                                                {mapTeamName(match.awayTeam)}
                                            </Td>
                                            <Td>{match.status}</Td>
                                            <Td>{getWinner(match)}</Td>
                                            <Td>
                                                {match.status ===
                                                'scheduled' ? (
                                                    <IconButton
                                                        variant="ghost"
                                                        colorScheme="blue"
                                                        aria-label="Action"
                                                        onClick={() =>
                                                            setMatchToEdit(
                                                                match,
                                                            )
                                                        }
                                                        icon={<EditIcon />}
                                                    />
                                                ) : (
                                                    '--'
                                                )}
                                            </Td>
                                        </Tr>
                                    )
                                } else return ''
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
                <EditMatchModal
                    match={matchToEdit}
                    clearMatch={() => setMatchToEdit(undefined)}
                    refetchMatches={async () => {
                        await refetchMatches()
                    }}
                />
            </>
        )
    }

    return (
        <Box mt="10">
            <Text fontSize={'x-large'}>Fixtures</Text>
            {!matchInfo || !roundData || roundData.rounds.length === 0 ? (
                <Text>No fixtures available to display</Text>
            ) : (
                fixturesTable(matchInfo, roundData)
            )}
        </Box>
    )
}

function EditMatchModal({
    match,
    clearMatch,
    refetchMatches,
}: {
    match: MatchDTO | undefined
    clearMatch: () => void
    refetchMatches: () => Promise<void>
}) {
    const [editMatch] = useEditMatch()

    const handleClick = async (action: Action) => {
        if (!match) return

        await editMatch({
            action,
            match,
        })
        await refetchMatches()
        clearMatch()
    }

    return (
        <StyledModal size="sm" isOpen={!!match} onClose={clearMatch}>
            <Text fontSize="lg" pt="2">
                Edit Match #{match?.matchId}
            </Text>
            <Box display="flex" flexDir="column" p="5">
                <Button
                    mt="4"
                    variant="outline"
                    colorScheme="blue"
                    onClick={() => handleClick(Action.ToggleLock)}
                    leftIcon={match?.isLocked ? <UnlockIcon /> : <LockIcon />}
                >
                    {match?.isLocked ? 'Unlock' : 'Lock'}
                </Button>
                <Tooltip
                    shouldWrapChildren
                    isDisabled={!match?.isFeatured}
                    label="Already featured"
                >
                    <Button
                        width="100%"
                        mt="4"
                        variant="outline"
                        colorScheme="blue"
                        onClick={() => handleClick(Action.SetFeatured)}
                        leftIcon={<StarIcon />}
                        disabled={match?.isFeatured}
                    >
                        Make Featured
                    </Button>
                </Tooltip>

                <Tooltip
                    shouldWrapChildren
                    label={
                        !match?.isLocked
                            ? 'Must lock the match first'
                            : 'When swapping teams, all of the existing tips will be automatically swapped. This can take some time if many users have placed tips for this game, so please ensure that you wait a minute or two before unlocking again'
                    }
                >
                    <Button
                        width="100%"
                        mt="4"
                        variant="outline"
                        colorScheme="blue"
                        onClick={() => handleClick(Action.SwapTeams)}
                        leftIcon={<MdSwapHoriz />}
                        disabled={!match?.isLocked}
                    >
                        Swap Teams
                    </Button>
                </Tooltip>
            </Box>
        </StyledModal>
    )
}
