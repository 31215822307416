import React from 'react'
import { Box, Input, Grid, GridItem, Button } from '@chakra-ui/react'
import { useGetCompetitionsByKeyword } from '../../../data'
import { BlockHeader } from '../Shared'
import { CompetitionItem } from './CompetitionItem'

export function CompetitionsSearch({ subject }: { subject?: string }) {
    const [keyword, setKeyword] = React.useState('')
    const {
        isLoading,
        data,
        refetch,
        isFetching,
    } = useGetCompetitionsByKeyword({
        keyword,
        subject,
    })

    return (
        <Box>
            <BlockHeader heading="Competitions search" />

            <Grid templateColumns="repeat(4, 1fr)" gap="2" mb="5">
                <GridItem colSpan={3}>
                    <Input
                        name="competition"
                        type="text"
                        placeholder="Enter competition name or code"
                        value={keyword}
                        onChange={(event) => setKeyword(event.target.value)}
                    />
                </GridItem>
                <GridItem
                    as={Button}
                    onClick={() => {
                        refetch()
                    }}
                    isLoading={isLoading}
                    disabled={!keyword}
                    colSpan={1}
                >
                    Search
                </GridItem>
            </Grid>

            {data && (
                <BlockHeader
                    heading={`${data ? data.length : 0} search results`}
                />
            )}

            {data?.length > 0 &&
                data.map((c) => {
                    return (
                        <CompetitionItem
                            key={c.pk}
                            comp={c}
                            subject={subject}
                            isAddBtn={true}
                            refetchCompetitions={refetch}
                            isRefetching={isFetching}
                        />
                    )
                })}
        </Box>
    )
}
